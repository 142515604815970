<template>
  <div class="login">
    <div class="loginbox">
      <ul class="boxhead">
        <li
          :class="glID == item.id ? 'gl' : ''"
          v-for="item in loginMenu"
          :key="item.id"
          @click="ChangeHandle(item)"
        >
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <div class="boxcontent">
        <div class="loginForm" v-if="formShow">
          <div class="title">可使用耘医通小程序账号登录</div>
          <div class="formcontent">
            <el-form
              label-position="center"
              hide-required-asterisk
              :rules="rules"
              label-width="80px"
              :model="LoginForm"
              ref="LoginForm"
            >
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model.trim="LoginForm.mobile"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code" id="spendCode">
                <el-input
                  v-model.trim="LoginForm.code"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  >.
                  <el-button slot="append" v-if="countDownShow"
                    >{{ time }}秒后可重新发送</el-button
                  >
                  <el-button slot="append" v-else @click="codeHandle()"
                    >发送验证码</el-button
                  >
                </el-input>
              </el-form-item>
              <div class="wechat">
                <a :href="SERVERURL" target="_self"
                  ><img src="../../assets/image/wx.webp" alt="" /><span
                    >微信登录</span
                  ></a
                >
              </div>
              <el-form-item id="mylogin">
                <el-button
                  id="loginbtn"
                  type="primary"
                  @click="submitForm('LoginForm')"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="registerForm" v-else>
          <div class="title">可使用耘医通小程序账号登录</div>
          <el-form
            label-position="center"
            hide-required-asterisk
            :rules="rules"
            label-width="80px"
            :model="registerForm"
            ref="registerForm"
          >
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model.trim="registerForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code" id="spendCode">
              <el-input
                v-model.trim="registerForm.code"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                >.
                <el-button slot="append" v-if="countDownShow"
                  >{{ time }}秒后可重新发送</el-button
                >
                <el-button slot="append" v-else @click="codeHandle2()"
                  >发送验证码</el-button
                >
              </el-input>
            </el-form-item>
            <div class="wechat"></div>
            <el-form-item id="mylogin">
              <el-button
                id="loginbtn"
                type="primary"
                @click="submitForm2('registerForm')"
                >注册</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <footerCard class="footerCard"></footerCard>
  </div>
</template>
<script>
import footerCard from "../../components/footerCard.vue";
import { spendCode, userLogin, registerUserPc } from "../API/login.js";
import { setToken } from "../../assets/js/storage";
export default {
  data() {
    return {
      SERVERURL: "http://www.gyycbj.com/Wechat/wchatLoginPc",
      countDownShow: false,
      time: 60, // 倒计时
      // 登录
      LoginForm: {
        mobile: "",
        code: "",
      },
      // 注册
      registerForm: {
        mobile: "",
        code: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入正确的电话号码",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "change" },
          { min: 6, max: 6, message: "长度应该是6个数字", trigger: "blur" },
        ],
      },
      loginMenu: [
        {
          name: "用户登录",
          id: 1,
          state: true,
        },
        {
          name: "账号注册",
          id: 2,
          state: false,
        },
      ],
      glID: 1,
      formShow: true,
      token: "",
    };
  },
  created() {
     this.token = localStorage.getItem("Authorization")
      ? localStorage.getItem("Authorization")
      : "";
    let myserve = window.location.origin.split("//")[1];
    if (myserve.split(":")[0] == "localhost") {
      console.log("本地环境");
    } else {
      this.SERVERURL = window.location.origin + "/Wechat/wchatLoginPc?token="+this.token;
    }
  },
  methods: {
    // 登录发验证码
    async codeHandle() {
      let str = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
      var state = this.LoginForm.mobile.search(str);
      if (this.LoginForm.mobile !== "" && state !== -1) {
        const res = await spendCode({
          mobile: this.LoginForm.mobile,
          type: 2,
        });
        if (res.data.code == "0000") {
          this.$message({
            message: "发送成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
        // 60秒倒计时
        this.countDownShow = true;
        let timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            this.countDownShow = false;
            this.time = 60;
            return clearInterval(timer);
          }
        }, 1000);
      }
      return this.$refs.LoginForm.validateField("mobile");
    },
    // 注册发验证码
    async codeHandle2() {
      let str = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
      var state = this.registerForm.mobile.search(str);
      if (this.registerForm.mobile !== "" && state !== -1) {
        const res = await spendCode({
          mobile: this.registerForm.mobile,
          type: 1,
        });
        if (res.data.code == "0000") {
          this.$message({
            message: "发送成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
        // 60秒倒计时
        this.countDownShow = true;
        let timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            this.countDownShow = false;
            this.time = 60;
            return clearInterval(timer);
          }
        }, 1000);
      }
      return this.$refs.LoginForm.validateField("mobile");
    },
    ChangeHandle(item) {
      this.glID = item.id;
      this.formShow = item.state;
    },
    // PC登录
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await userLogin(this.LoginForm);
          if (res.data.code == "0000") {
            // 登录成功
            this.$message({
              message: "登录成功",
              type: "success",
            });
            setToken(res.data.data);
            return this.$router.push({ name: "UserInfo" });
          }
          if (res.data.code == "4003") {
            return window.open(
              "http://www.gyycbj.com/Wechat/wechatRegisterPc?token="+this.token,
              "_self"
            );
          }
          return this.$message(res.data.msg);
        } else {
          return false;
        }
      });
    },
    // PC注册
    submitForm2(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await registerUserPc(this.registerForm);
          if (res.data.code !== "0000") {
            return this.$message(res.data.msg);
          }
          window.open("http://www.gyycbj.com/Wechat/wechatRegisterPc?token="+this.token, "_self");
        } else {
          return false;
        }
      });
    },
  },
  components: {
    footerCard,
  },
};
</script>
<style scoped lang="scss">
.login {
  padding: 80px 0px;
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  .loginbox {
    width: 834px;
    margin: 0px auto;
    box-shadow: -10px 0px 32px 32px rgb(24 25 34 / 6%);
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 200px;
    @media screen and (max-width: 540px) {
      width: 100%;
      margin-bottom: 20px;
    }
    .boxhead {
      width: 100%;
      height: 85px;
      line-height: 80px;
      background: url("../../assets/image/login_bg.webp") no-repeat 100% 100%;
      margin: 0px 0px 20px 0;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
      li {
        color: #757477;
        font-size: 26px;
        width: 50%;
        height: 100%;
        float: left;
        text-align: center;
        line-height: 85px;
        cursor: pointer;
      }
      .gl {
        color: #293f8e;
        position: relative;
        ::after {
          content: "";
          position: absolute;
          left: 50%;
          margin-left: -60px;
          bottom: 15px;
          height: 4px;
          width: 120px;
          background-color: #293f8e;
        }
        span {
          font-weight: 600;
          font-size: 26px;
        }
      }
    }
    .boxcontent {
      height: 464px;
      background-color: #ffffff;
      .title {
        text-align: center;
        color: #666666;
        font-size: 16px;
        padding: 17px 0;
      }
      .loginForm {
        .formcontent {
          padding: 0 160px;
          @media screen and (max-width: 540px) {
            padding: 0 20px;
          }
          .el-form-item {
            height: 60px;
          }
          ::v-deep .el-form-item__label {
            line-height: 60px;
            border: 1px solid #dcdfe6;
            border-right: none;
            height: 60px;
          }
          ::v-deep .el-input__inner {
            line-height: 60px;
            height: 60px;
            border-radius: 0;
          }
          #loginbtn {
            padding: 0;
            width: 510px;
            color: #ffffff;
            font-size: 18px;
            height: 60px;
            background: #293f8e;
            border-radius: 2px;
            @media screen and (max-width: 540px) {
              width: 100%;
            }
          }
          #mylogin {
            margin-top: 30px;
            ::v-deep .el-form-item__content {
              margin-left: 0 !important;
            }
          }
          .wechat {
            cursor: pointer;
            a {
              display: flex;
              align-items: center;
            }
            img {
              margin-right: 5px;
            }
          }
          #spendCode {
            ::v-deep .el-input-group__append {
              color: #293f8e;
              cursor: pointer;
            }
          }
        }
      }

      .registerForm {
        padding: 0 160px;
        @media screen and (max-width: 540px) {
          padding: 0 20px;
        }
        .el-form-item {
          height: 60px;
        }
        ::v-deep .el-form-item__label {
          line-height: 60px;
          border: 1px solid #dcdfe6;
          border-right: none;
          height: 60px;
        }
        ::v-deep .el-input__inner {
          line-height: 60px;
          height: 60px;
          border-radius: 0;
        }
        #loginbtn {
          padding: 0;
          width: 510px;
          color: #ffffff;
          font-size: 18px;
          height: 60px;
          background: #293f8e;
          border-radius: 2px;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
        #mylogin {
          margin-top: 30px;
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
        }
        .wechat {
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        #spendCode {
          ::v-deep .el-input-group__append {
            color: #293f8e;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
